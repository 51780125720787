import * as THREE from "three";
import { ElementStyle } from "@anderjason/web";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import terrainVertexShader from './shaders/terrain/vertex.glsl'
import terrainFragmentShader from './shaders/terrain/fragment.glsl'
import terrainDepthVertexShader from './shaders/terrainDepth/vertex.glsl'
import terrainDepthFragmentShader from './shaders/terrainDepth/fragment.glsl'

import { Actor } from "skytree";

export interface ThreeMainProps {
  parentElement: HTMLElement;
}

export class ThreeMain extends Actor<ThreeMainProps> {
  onActivate() {
    const managedCanvas = this.addActor(
      CanvasStyle.toManagedElement({
        tagName: "canvas",
        parentElement: this.props.parentElement,
      })
    );

    const canvas = managedCanvas.element;

    // Scene
    const scene = new THREE.Scene();

    const sizes: any = {
      width: window.innerWidth,
      height: window.innerHeight,
      pixelRatio: Math.min(window.devicePixelRatio, 2),
    };

    window.addEventListener("resize", () => {
      // Update sizes
      sizes.width = window.innerWidth;
      sizes.height = window.innerHeight;
      sizes.pixelRatio = Math.min(window.devicePixelRatio, 2);

      // Update camera
      camera.instance.aspect = sizes.width / sizes.height;
      camera.instance.updateProjectionMatrix();

      // Update renderer
      renderer.setSize(sizes.width, sizes.height);
      renderer.setPixelRatio(sizes.pixelRatio);

      // Update effect composer
      effectComposer.setSize(sizes.width, sizes.height);
      effectComposer.setPixelRatio(sizes.pixelRatio);
    });

    /**
     * Camera
     */
    const camera: any = {};
    camera.position = new THREE.Vector3();
    camera.rotation = new THREE.Euler();
    camera.rotation.reorder("YXZ");

    // Base camera
    camera.instance = new THREE.PerspectiveCamera(
      75,
      sizes.width / sizes.height,
      0.1,
      100
    );
    camera.instance.rotation.reorder("YXZ");
    scene.add(camera.instance);

    // Orbit controls
    const orbitControls = new OrbitControls(camera.instance, canvas);
    orbitControls.enabled = false;
    orbitControls.enableDamping = true;

    /**
     * Terrain
     */
    const terrain: any = {};

    // Texture
    terrain.texture = {};
    terrain.texture.visible = false;
    terrain.texture.linesCount = 5;
    terrain.texture.bigLineWidth = 0.045;
    terrain.texture.smallLineWidth = 0.01;
    terrain.texture.smallLineAlpha = 0.6;
    terrain.texture.width = 1;
    terrain.texture.height = 128;
    terrain.texture.canvas = document.createElement("canvas");
    terrain.texture.canvas.width = terrain.texture.width;
    terrain.texture.canvas.height = terrain.texture.height;
    terrain.texture.canvas.style.position = "fixed";
    terrain.texture.canvas.style.top = 0;
    terrain.texture.canvas.style.left = 0;
    terrain.texture.canvas.style.width = "50px";
    terrain.texture.canvas.style.height = `${terrain.texture.height}px`;
    terrain.texture.canvas.style.zIndex = 1;

    if (terrain.texture.visible) {
      document.body.append(terrain.texture.canvas);
    }

    terrain.texture.context = terrain.texture.canvas.getContext("2d");

    terrain.texture.instance = new THREE.CanvasTexture(terrain.texture.canvas);
    terrain.texture.instance.wrapS = THREE.RepeatWrapping;
    terrain.texture.instance.wrapT = THREE.RepeatWrapping;
    terrain.texture.instance.magFilter = THREE.NearestFilter;

    terrain.texture.update = () => {
      terrain.texture.context.clearRect(
        0,
        0,
        terrain.texture.width,
        terrain.texture.height
      );

      // Big line
      const actualBigLineWidth = Math.round(
        terrain.texture.height * terrain.texture.bigLineWidth
      );
      terrain.texture.context.globalAlpha = 1;
      terrain.texture.context.fillStyle = "#ffffff";

      terrain.texture.context.fillRect(
        0,
        0,
        terrain.texture.width,
        actualBigLineWidth
      );

      // Small lines
      const actualSmallLineWidth = Math.round(
        terrain.texture.height * terrain.texture.smallLineWidth
      );
      const smallLinesCount = terrain.texture.linesCount - 1;

      for (let i = 0; i < smallLinesCount; i++) {
        terrain.texture.context.globalAlpha = terrain.texture.smallLineAlpha;
        terrain.texture.context.fillStyle = "#00ffff";
        terrain.texture.context.fillRect(
          0,
          actualBigLineWidth +
            Math.round(
              (terrain.texture.height - actualBigLineWidth) /
                terrain.texture.linesCount
            ) *
              (i + 1),
          terrain.texture.width,
          actualSmallLineWidth
        );
      }

      // Update texture instance
      terrain.texture.instance.needsUpdate = true;
    };

    terrain.texture.update();

    // Geometry
    terrain.geometry = new THREE.PlaneGeometry(1, 1, 1000, 1200);
    terrain.geometry.rotateX(-Math.PI * 0.5);

    // Uniforms
    terrain.uniforms = {
      uTexture: { value: terrain.texture.instance },
      uElevation: { value: 2 },
      uElevationValley: { value: 0.4 },
      uElevationValleyFrequency: { value: 3 },
      uElevationGeneral: { value: 0.5 },
      uElevationGeneralFrequency: { value: 0.2 },
      uElevationDetails: { value: 0.25 },
      uElevationDetailsFrequency: { value: 2 },
      uTextureFrequency: { value: 5 },
      uTextureOffset: { value: 0.585 },
      uTime: { value: 0 },
      uHslHue: { value: 0.1 },
      uHslHueOffset: { value: 0.5 },
      uHslHueFrequency: { value: 5.0 },
      uHslTimeFrequency: { value: 0.03 },
      uHslLightness: { value: 0.2 },
      uHslLightnessVariation: { value: 0.1 },
      uHslLightnessFrequency: { value: 7.0 },
    };

    // Material
    terrain.material = new THREE.ShaderMaterial({
      transparent: true,
      // blending: THREE.AdditiveBlending,
      side: THREE.DoubleSide,
      vertexShader: terrainVertexShader,
      fragmentShader: terrainFragmentShader,
      uniforms: terrain.uniforms,
    });

    // Depth material
    const uniforms = THREE.UniformsUtils.merge([
      THREE.UniformsLib.common,
      THREE.UniformsLib.displacementmap,
    ]);
    for (const uniformKey in terrain.uniforms) {
      uniforms[uniformKey] = terrain.uniforms[uniformKey];
    }

    terrain.depthMaterial = new THREE.ShaderMaterial({
      uniforms: uniforms,
      vertexShader: terrainDepthVertexShader,
      fragmentShader: terrainDepthFragmentShader,
    });

    terrain.depthMaterial.depthPacking = THREE.RGBADepthPacking;
    terrain.depthMaterial.blending = THREE.NoBlending;

    // Mesh
    terrain.mesh = new THREE.Mesh(terrain.geometry, terrain.material);
    terrain.mesh.scale.set(10, 10, 10);
    terrain.mesh.userData.depthMaterial = terrain.depthMaterial;
    scene.add(terrain.mesh);



    /**
     * Renderer
     */
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
    });
    renderer.setClearColor("#111111", 1);
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(sizes.pixelRatio);

    // Effect composer
    const renderTarget = new THREE.WebGLMultisampleRenderTarget(800, 600, {
      minFilter: THREE.LinearFilter,
      magFilter: THREE.LinearFilter,
      format: THREE.RGBAFormat,
      encoding: THREE.sRGBEncoding,
    });
    const effectComposer = new EffectComposer(renderer);
    effectComposer.setSize(sizes.width, sizes.height);
    effectComposer.setPixelRatio(sizes.pixelRatio);

    // Render pass
    const renderPass = new RenderPass(scene, camera.instance);
    effectComposer.addPass(renderPass);


    /**
     * View
     */
    const view: any = {};
    view.index = 0;
    view.settings = [
      {
        position: { x: 1, y: 0.87, z: -0.97 },
        rotation: { x: -0.838, y: 0, z: 0 },
        focus: 1.36,
      },
    ];
    view.current = view.settings[view.index];

    // Apply
    view.apply = () => {
      // Camera
      camera.position.copy(view.current.position);
      camera.rotation.x = view.current.rotation.x;
      camera.rotation.y = view.current.rotation.y;
    };

    view.apply();

    /**
     * Animate
     */
    const clock = new THREE.Clock();
    let lastElapsedTime = 0;

    const tick = () => {
      const elapsedTime = clock.getElapsedTime();
      const deltaTime = elapsedTime - lastElapsedTime;
      lastElapsedTime = elapsedTime;

      // Update terrain
      terrain.uniforms.uTime.value = elapsedTime;

      // Update controls
      if (orbitControls.enabled) {
        orbitControls.update();
      }

      // Camera
      camera.instance.position.copy(camera.position);

      camera.instance.rotation.x = camera.rotation.x;
      camera.instance.rotation.y = camera.rotation.y;

      // Render
      // renderer.render(scene, camera.instance)
      effectComposer.render();

      // Call tick again on the next frame
      window.requestAnimationFrame(tick);
    };

    tick();
  }
}

const CanvasStyle = ElementStyle.givenDefinition({
  elementDescription: "Canvas",
  css: `
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    width: 100%;
    height: 100%;
  `,
});
